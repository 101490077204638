/*--------------------------------------------------------
  フッター
----------------------------------------------------------*/
.f-sns {
  margin-top: 78px;
  margin-bottom: 60px;
  @include mq(sp) {
    margin-top: 54px;
    margin-bottom: 40px;
  }
  ul {
    @include flex();
    justify-content: center;
    @include mq(sp) {
      text-align: center;
    }
    li {
      margin-bottom: 18px;
    }
  }
}
.footer {
  background: $light-main;
  &__inner {
    @include base-wrap--space();
    padding: 40px 0;
    @include mq(sp) {
      width: auto;
      margin: 10px auto 0;
      padding: 40px 0;
    }
  }
  &__logo {
    float: left;
    margin-right: 65px;
    margin-top: 24px;
    @include mq(sp) {
      float: none;
      margin: 0;
      text-align: center;
    }
    img {
      width: 150px;
      @include mq(sp) {
        width: 120px;
      }
    }
  }
  &__address {
    float: left;
    margin-top: 30px;
    font-weight: 400;
    @include mq(sp) {
      float: none;
      margin-bottom: 40px;
      text-align: center;
    }
  }
  &__contact {
    float: right;
    margin-top:20px;
    @include mq(sp) {
      float: none;
      text-align: center;
    }
    a {
      margin-bottom: 14px;
      @include mq(sp) {
        margin-bottom: 25px;
      }
    }
  }

  .copyright {
    margin-top: 20px;
    font-size: 13px;
    text-align: center;
    -webkit-font-smoothing: antialiased;
    span {
      padding: 4px 8px;
      background: rgba(255,255,255,0.3);
      a {
        text-decoration: none;
      }
    }
  }
}

/* ページ上へ戻る */
#TopLink {
  position: fixed;
  bottom: 30px;
  left: 50%;
  margin-left: 410px;
  z-index: 3;
  @include mq(sp) {
    left: auto;
    right: 10px;
    margin-left: 0;
  }
  a {
    display: block;
    width: 100px;
    height: 100px;
    padding-top: 50px;
    border-radius: 50%;
    box-sizing: border-box;
    background: #fff url(../img/common/icn_logo.svg) center 16px no-repeat;
    background-size: 35px 35px;
    font-size: 15px;
    text-decoration: none;
    text-align: center;
    @include mq(sp) {
      width: 80px;
      height: 80px;
      background-size: 30px 30px;
      background-position: center 13px;
      padding-top: 42px;
      font-size: 13px;
    }
    &:hover {
      @include mq(pc) {
        background-color: $acc;
      }
    }
  }
}
