@charset "utf-8";

/*--------------------------------------------------------
  テーブル
----------------------------------------------------------*/
.main {
  table {
    @include box-sizing(border-box);
    margin-bottom: 60px;
    border-top: 1px solid $black;
    tr {
      border-bottom: 1px solid $black;
    }
    th {
      @include box-sizing(border-box);
      padding: 20px;
      background: $light-gray;
      color: $black;
      font-size: 16px;
      text-align: left;
      vertical-align: top;
      white-space: nowrap;
      @include mq(sp) {
        display: block;
        width: 100%;
        padding: 6px 15px;
      }
    }
    td {
      @include box-sizing(border-box);
      padding: 20px;
      font-size: 15px;
      line-height: 1.5;
      @include mq(sp) {
        display: block;
        width: 100%;
        padding: 15px 0;
        font-size: 15px;
      }
    }
  }
}

.table-scroll {
  overflow: auto;
  white-space: nowrap;
  &::-webkit-scrollbar {
    height: 5px;
  }
  &::-webkit-scrollbar-track {
    background: #light-main;
  }
  &::-webkit-scrollbar-thumb {
    background: #main;
  }
  th {
    @include mq(sp) {
      display: table-cell !important;
      width: auto !important;
      padding: 10px !important;
      font-size: 16px !important;
    }
  }
  td {
    @include mq(sp) {
      display: table-cell !important;
      width: auto !important;
      padding: 10px !important;
      font-size: 15px !important;
    }
  }
}