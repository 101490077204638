@charset "utf-8";

/*--------------------------------------------------------
  固定ページ共通
----------------------------------------------------------*/

/* サブページ共通 */
@include mq(sp) {
  .sp-ta-center {
    text-align: center;
  }
}

/* CONTACT */
.contact {
  font-size: 19px;
  line-height: 2.2;
  @include mq(sp) {
    font-size: 16px;
    line-height: 1.8;
  }
  &_message {
    margin-bottom: 40px;
    @include mq(sp) {
      margin-bottom: 20px;
    }
    p {
      text-align: center;
    }
  }
  &_tel {
    margin-bottom: 60px;
    @include mq(sp) {
      margin-bottom: 30px;
    }
    &_01 {
      margin-bottom: 30px;
      @include mq(sp) {
        margin-bottom: 15px;
      }
    }
  }
  &_mail {
    margin-bottom: 40px;
    @include mq(sp) {
      margin-bottom: 20px;
    }
  }
  &_form {
    margin-bottom: 80px;
    @include mq(sp) {
      margin-bottom: 40px;
    }
  }
  &_notice {
    padding-bottom: 100px;
    @include mq(sp) {
      padding-bottom: 50px;
    }
    &_01 {
    }
    &_02 {
      margin-bottom: 80px;
      @include mq(sp) {
        margin-bottom: 40px;
      }
    }
  }
  &_attention {
    p {
      font-size: 15px;
    }
  }

  .btn-def-big {
    margin: 60px 0 80px;
    @include mq(sp) {
      margin: 30px 0 40px;
    }
  }
}

.contents--page .main ul.form-checkbox {
  margin: 0;
  & > li {
    margin-bottom: 0;
    padding-left: 0;
    &::before {
      display: none;
    }
  }
}


/* ブログ */
.blog {
  .blog-title {
    margin-bottom: 50px;
    color: $light-sub;
    font-size: 28px;
    @include mq(sp) {
      margin-bottom: 30px;
      font-size: 20px;
    }
  }
  h3 {
    a {
      color: #333;
      text-decoration: none;
      &:hover {
        color: #777;
        text-decoration: underline;
      }
    }
  }
  .post {
    margin-bottom: 50px;
  }
  .eye-catch {
    margin-bottom: 20px;
    text-align: center;
  }
  .meta {
    margin: 20px 0;
    padding: 10px 15px;
    background: #eee;
    font-size: 13px;
    span {
      margin-right: 20px;
    }
    .category-head {
      display: inline-block;
      margin-bottom: 10px;
      padding: 0 4px;
      background: #B2B200;
      color: #fff;
      border-radius: 3px;
      font-size: 12px;
    }
  }
  .post-wrap {
    @include flex();
    .eye-catch {
      width: 30%;
      @include mq(sp) {
        width: auto;
        margin-bottom: 20px;
        text-align: center;
      }
      img {
        width: 90%;
      }
    }
    .post-main {
      width: 70%;
      @include mq(sp) {
        width: 100%;
      }
    }
  }
}
