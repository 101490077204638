/* Slider */
.slick-loading .slick-list
{
  background: #fff url('./images/ajax-loader.gif') center center no-repeat;
}

/* Icons */

/* Arrows */
.slick-prev,
.slick-next
{
    font-size: 0;
    line-height: 0;

    position: absolute;
    top: 50%;
    z-index: 1000;

    display: block !important;

    width: 54px;
    height: 58px;
    padding: 0;
    -webkit-transform: translate(0, -50%);
    -ms-transform: translate(0, -50%);
    transform: translate(0, -50%);

    cursor: pointer;

    color: transparent;
    border: none;
    outline: none;
    background: transparent;
    overflow: visible;
    @include mq(sp) {
        width: 25px;
        height: 25px;
    }
}

.slick-prev:hover,
.slick-prev:focus,
.slick-next:hover,
.slick-next:focus
{
    color: transparent;
    outline: none;
}

.slick-prev
{
    left: 11.5%;
    background: url(../img/top/slide_prev.png) 0 0 no-repeat;
    background-size: 48px auto;
    @include mq(sp) {
        left: 10px;
        background-size: 25px auto;
    }
}
[dir='rtl'] .slick-prev
{
    right: -25px;
    left: auto;
}


.slick-next
{
    right: 11.5%;
    background: url(../img/top/slide_next.png) 0 0 no-repeat;
    background-size: 48px auto;
    @include mq(sp) {
        right: 10px;
        background-size: 25px auto;
    }
}
[dir='rtl'] .slick-next
{
    right: auto;
    left: -25px;
}

.slick-gallery {
    .slick-prev {
        left: 6px;
        background: none;
        &::before {
            position: absolute;
            top: 0;
            left: 0;
            content: "";
            display: block;
            width: 20px;
            height: 20px;
            border-top: 4px solid $main;
            border-right: 4px solid $main;
            transform: rotate(225deg);
        }
    }
    .slick-next {
        right: -26px;
        background: none;
        &::before {
            position: absolute;
            top: 0;
            left: 0;
            content: "";
            display: block;
            width: 20px;
            height: 20px;
            border-top: 4px solid $main;
            border-right: 4px solid $main;
            transform: rotate(45deg);
        }
    }
}


/* Dots */
.slick-dotted.slick-slider
{
    margin-bottom: 80px;
    @include mq(sp) {
        margin-bottom: 60px;
    }
}

.slick-dots
{
    position: absolute;
    bottom: -40px;

    display: block;

    width: 100%;
    padding: 0;
    margin: 0;

    list-style: none;

    text-align: center;
    @include mq(sp) {
        bottom: -25px;
    }
}
.slick-dots li
{
    position: relative;

    display: inline-block;

    width: 8px;
    height: 8px;
    margin: 0 6px;
    padding: 0;

    cursor: pointer;
}
.slick-dots li button
{
    font-size: 0;
    line-height: 0;

    display: block;

    width: 8px;
    height: 8px;

    cursor: pointer;

    color: transparent;
    border: 0;
    outline: none;
    background: transparent;
}
.slick-dots li button:hover,
.slick-dots li button:focus
{
    outline: none;
}
.slick-dots li button:hover:before,
.slick-dots li button:focus:before
{
    opacity: 1;
}
.slick-dots li button:before
{
    font-family: 'slick';
    font-size: 6px;
    line-height: 20px;

    position: absolute;
    top: 0;
    left: 0;

    width: 8px;
    height: 8px;
    border-radius: 50%;
    background: $main;

    content: '';
    text-align: center;

    color: black;

    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

.slick-dots li.slick-active button:before
{
    background: $main;
    color: black;
}

@media screen and (max-width: 749px) {
    .slick-dots li,
    .slick-dots li button,
    .slick-dots li button:before {
        width: 9px;
        height: 9px;
        margin: 0 5px;
        border-radius: 50%;
    }
}