/*--------------------------------------------------------
	トップページ
----------------------------------------------------------*/

#MainImageWrapper {
}
#MainImage {
    position: relative;
    img {
      width: 100%;
    }
}
#SPMainImageWrapper {
  padding-top: 62px;
}

.top {
  &__line {
    margin-bottom: 60px;
    text-align: center;
  }
}

/* トップページ　BLOG */
.top {
  &__blog-feed {
    @include main-wrap--space();
    margin-bottom: 80px;
    @include mq(sp) {
      margin-bottom: 60px;
    }
    h2 {
    }
    ul {
      li {
        padding: 14px 0;
        padding-left: 94px;
        text-indent: -84px;
        border-bottom: 1px dotted $black;
        line-height: 1.5;
        .date {
          margin-right: 1em;
          color: $black;
        }
        .title {
          a {
            font-size: 16px;
            text-decoration: none;
            &:hover {
              text-decoration: underline;
            }
          }
        }
      }
    }
  }
}

/* トップページ　ABOUT */
.top {
  &__about {
    @include box-sizing(border-box);
    @include base-wrap();
    @include clearfix();
    padding: 40px 55px 20px;
    @include mq(sp) {
      width: auto;
      padding: 40px 30px 20px;
      background: none;
      text-align: center;
    }
    &__wrap {
      margin-bottom: 60px;
      padding-bottom: 100px;
      background: $light-acc url(../img/common/bg_bottom.png) center bottom no-repeat;
      @include mq(sp) {
        margin-bottom: 10px;
      }
    }
    p {
      margin-bottom: 40px;
    }
    .left {
      width: 460px;
      font-size: 15px;
      @include mq(sp) {
        float: none;
        width: auto;
      }
    }
    .right {
      width: 420px;
      margin-top: -40px;
      @include mq(sp) {
        float: none;
        width: auto;
        margin-top: 0;
        margin-bottom: 30px;
        text-align: center;
      }
      img {
      }
    }
  }
}

/* トップページ　CONCEPT */
.top {
  &__menu {
    width: 940px;
    margin: 0 auto 70px;
    @include mq(sp) {
      width: auto;
      margin: 0 20px 50px;
    }
    &__image {
      width: 274px;
      margin-bottom: 20px;
      @include mq(sp) {
        margin: 0 auto 20px;
      }
    }
    &__info {
      @include mq(sp) {
      }
    }
    ul {
      @include flex();
      margin-bottom: 40px;
      @include mq(sp) {
        margin-bottom: 20px;
      }
      li {
        width: 29%;
        margin-bottom: 20px;
        @include mq(sp) {
          width: 100%;
          margin-bottom: 40px;
        }
        h3 {
          margin-bottom: 20px;
          font-size: 20px;
          font-weight: 500;
          letter-spacing: 0;
          line-height: 1.27;
          text-align: center;
          @include mq(sp) {
            font-size: 22px;
          }
        }
        p {
          margin-bottom: 24px;
          line-height: 2;
          @include mq(sp) {
          }
        }
      }
    }
  }
}

/* トップページ　BANNER */
.top {
  &__banner {
    width: 940px;
    margin: 0 auto 30px;
    @include mq(sp) {
      width: auto;
      margin: 0 20px 30px;
    }
  }
}