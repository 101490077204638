@charset "utf-8";

$black: #333;
$gray: #ccc;/* グレー */
$light-gray: #f2f4f7;/* 薄いグレー */
$main: #6ab9e6;/* 青  */
$light-main: #b4e2f7;/* 水色 */
$sub: #8f6f46;/* 茶 */
$light-sub: #bb9862;/* 薄茶 */
$acc: #fdedf1;/* 桃 */
$light-acc: #fcf7fa;/* 薄桃 */
$gra: linear-gradient(90deg, rgba(245,192,202,1) 0%, rgba(219,223,238,1) 100%);
$gra2: linear-gradient(-90deg, rgba(245,192,202,1) 0%, rgba(219,223,238,1) 100%);

$base-lh: 2;
$base-fs: 16px;
$base-fs-sp: 14px;

@import url('https://fonts.googleapis.com/css2?family=Noto+Serif+JP:wght@300;400;500&family=Vollkorn&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Shippori+Mincho:wght@400;500&display=swap');

$font-notoserif: 'Noto Serif JP', serif;
$font-shippori: 'Shippori Mincho', serif;