/*--------------------------------------------------------
	ボタン
----------------------------------------------------------*/

// top button
.btn-more {
  text-align: center;
  a {
    display: inline-block;
    width: 140px;
    padding: 3px 0 4px;
    background: $light-main;
    color: $black;
    font-size: 12px;
    font-weight: 400;
    &:hover {
      @include mq(pc) {
        opacity: .8;
      }
    }
  }
}

// round button (top line, sp-menu)
.btn-ppl-round {
  a {
    display: inline-block;
    width: 280px;
    padding: 10px 0 12px;
    border-radius: 30px;
    background: $acc;
    color: $black;
    font-size: 18px;
    font-weight: 500;
    text-align: center;
    text-decoration: none;
    box-sizing: border-box;
    -webkit-font-smoothing: antialiased;
    @include mq(sp) {
      padding: 6px 0 8px;
      font-size: 16px;
    }
    &:hover {
      @include mq(pc) {
        opacity: .8;
      }
    }
  }
}

.btn-white-round {
  margin-bottom: 12px;
  a {
    display: inline-block;
    width: 280px;
    padding: 10px 0 12px;
    border-radius: 30px;
    background: white;
    color: $black;
    font-size: 18px;
    font-weight: 500;
    text-align: center;
    text-decoration: none;
    box-sizing: border-box;
    -webkit-font-smoothing: antialiased;
    @include mq(sp) {
      padding: 6px 0 8px;
      font-size: 16px;
    }
    &:hover {
      @include mq(pc) {
        opacity: .8;
      }
    }
  }
}

// icn button
.btn-fb-icon {
  margin-right: 10px;
  a {
    display: block;
    width: 135px;
    padding: 10px 0 6px;
    border-radius: 30px;
    background: white;
    img {
      width: 14px;
      height: 25px;
      vertical-align: top;
    }
  }
}

.btn-ig-icon {
  a {
    display: block;
    width: 135px;
    padding: 10px 0 6px;
    border-radius: 30px;
    background: white;
    img {
      width: 28px;
      height: 24px;
      vertical-align: top;
    }
  }
}

// footer sns button
.btn-fb {
  margin-right: 18px;
  @include mq(sp) {
    margin-right: 0;
  }
  a {
    display: inline-block;
    width: 280px;
    padding: 10px 0 12px;
    border-radius: 30px;
    background: $acc url(../img/common/icn_fb.svg) 50px center no-repeat;
    background-size: 23px 23px;
    color: $main;
    font-size: 16px;
    font-weight: 500;
    text-align: center;
    text-decoration: none;
    -webkit-font-smoothing: antialiased;
    @include mq(sp) {
      font-size: 15px;
    }
    &:hover {
      @include mq(pc) {
        opacity: .8;
      }
    }
  }
}

.btn-ig {
  a {
    display: inline-block;
    width: 280px;
    padding: 10px 0 12px;
    border-radius: 30px;
    background: $acc url(../img/common/icn_ig.svg) 50px center no-repeat;
    background-size: 23px 23px;
    color: $main;
    font-size: 16px;
    font-weight: 500;
    text-align: center;
    text-decoration: none;
    -webkit-font-smoothing: antialiased;
    @include mq(sp) {
      font-size: 15px;
    }
    &:hover {
      @include mq(pc) {
        opacity: .8;
      }
    }
  }
}

// footer button
.btn-white {
  @include mq(sp) {
    text-align: center;
  }
  a {
    display: inline-block;
    width: 280px;
    padding: 20px 0 22px;
    background: white;
    font-size: 20px;
    font-weight: 400;
    line-height: 1.2;
    text-align: center;
    @include mq(sp) {
      padding: 16px 0 18px;
      font-size: 18px;
    }
    &:hover {
      @include mq(pc) {
        opacity: .8;
      }
    }
  }
}

.btn-ppl {
  a {
    display: inline-block;
    width: 280px;
    padding: 4px 0 5px;
    background: $acc;
    color: $black;
    font-size: 17px;
    font-weight: 500;
    text-align: center;
    text-decoration: none;
    box-sizing: border-box;
    -webkit-font-smoothing: antialiased;
    @include mq(sp) {
      font-size: 15px;
    }
    &:hover {
      @include mq(pc) {
        opacity: .8;
      }
    }
  }
}

.btn-def-big {
  text-align: center;
  a {
    position: relative;
    display: inline-block;
    width: 320px;
    padding: 14px 0 15px;
    border-radius: 40px;
    background: $light-main;
    color: $black;
    font-size: 20px;
    text-align: center;
    text-decoration: none;
    -webkit-font-smoothing: antialiased;
    @include mq(sp) {
      width: 100%;
      font-size: 18px;
    }
    &:hover {
      @include mq(pc) {
        opacity: .8;
      }
    }
  }
}