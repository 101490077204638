@charset "utf-8";

/*--------------------------------------------------------
  タイトル
----------------------------------------------------------*/
/* 見出し */
.t-top-01 {
  margin-bottom: 20px;
  padding: 0 15px 0 40px;
  border-bottom: 1px solid $main;
  background: url(../img/common/icn_logo.svg) left 9px no-repeat;
  background-size: 31px 31px;
  color: $main;
  font-size: 24px;
  font-weight: 400;
  @include mq(sp) {
    margin-top: 30px;
    margin-bottom: 10px;
    padding: 0 0 0 30px;
    background-position: left 8px;
    background-size: 24px 24px;
    font-size: 20px;
  }
  a {
    color: #000;
    text-decoration: none;
    &:hover {
      text-decoration: underline;
    }
  }
}

.t-top-02 {
  margin-bottom: 40px;
  padding: 0;
  border: 0;
  background: none;
  color: $light-sub;
  font-size: 32px;
  font-weight: 500;
  @include mq(sp) {
    margin-bottom: 20px;
    font-size: 22px;
    line-height: 1.2;
  }
}

.t-top-03 {
  margin-bottom: 40px;
  padding: 0;
  border: 0;
  background: none;
  color: $main;
  font-size: 38px;
  font-weight: 500;
  text-align: center;
  @include mq(sp) {
    font-size: 26px;
    line-height: 1.2;
  }
}

.t-top-04 {
  margin-bottom: 30px;
  padding: 0 30px;
  background: $acc;
  font-size: 21px;
  font-weight: 400;
  @include mq(sp) {
    margin-bottom: 20px;
    padding: 7px 20px;
    font-size: 18px;
  }
}

.contents--page {
  h1 {
    margin-bottom: 50px;
    color: $light-sub;
    font-size: 36px;
    font-weight: 500;
    text-align: center;
    @include mq(sp) {
      margin-bottom: 30px;
      padding-top: 30px;
      font-size: 26px;
    }
    a {
      color: $sub;
      text-decoration: none;
      &:hover {
        text-decoration: underline;
      }
    }
    &.page-mv__title--blog {
      font-size: 36px;
      line-height: 1.8;
      text-align: left;
    }
  }
}

.title-header {
  position: relative;
  .more-link {
    position: absolute;
    top: 10px;
    right: 0;
    @include mq(sp) {
      top: 7px;
    }
    a {
      display: inline-block;
      color: $black;
      font-size: 14px;
      text-decoration: none;
      @include mq(sp) {
        font-size: 13px;
      }
      &:hover {
        @include mq(pc) {
          opacity: .8;
        }
      }
    }
  }
}

.notice {
  color: #777;
  font-size: 13px;
}